
import { createSlice } from '@reduxjs/toolkit'

const navSlice = createSlice({
    name: 'nav',
    initialState: { navState: false},
    reducers: {
      open(state) {
        state.navState = true;
      },
      close(state) {
        state.navState = false;
      },
    },
  });
  
  export const navActions = navSlice.actions;
  
  export default navSlice;

import { NavLink } from 'react-router-dom';
import  './Button.css';

const Button = (props) => {
    return <NavLink className='button' to={props.to} onClick={props.onClick} >
        <div className='button-text'>{props.name}</div>
    </NavLink>
};

export default Button;


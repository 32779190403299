import TimerBlock from './TimerBlock/TimerBlock';

import Item from './Item/Item';

import './Items.css'

import img1 from '../../Assets/token_img_1.svg';
import img2 from '../../Assets/token_img_2.svg';
import img3 from '../../Assets/token_img_3.svg';

import base from '../../Store/base'

const Items = () => {
    return <div className='items-block'>
        <div className='container'>
            
            <TimerBlock></TimerBlock>
            <div className='items-container'>
                <Item id={base.one.id} img = {img1} title={base.one.title} description={base.one.description} price={base.one.price}></Item>
                <Item id={base.two.id}  img = {img2} title={base.two.title} description={base.two.description} price={base.two.price}></Item>
                <Item id={base.three.id}  img = {img3} title={base.three.title} description={base.three.description} price={base.three.price}></Item>
                <Item id={base.three.id}  img = {img3} title={base.three.title} description={base.three.description} price={base.three.price}></Item>
           
            </div>
        </div>
    </div>
    
}

export default Items;

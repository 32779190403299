
import { createSlice } from '@reduxjs/toolkit'

const walletSlice = createSlice({
    name: 'wallet',
    initialState: { walletState: false},
    reducers: {
      open(state) {
        state.walletState = true;
      },
      close(state) {
        state.walletState = false;
      },
    },
  });
  
  export const walletActions = walletSlice.actions;
  
  export default walletSlice;

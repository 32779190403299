import './ProgresBar.css'
const ProgresBar = () => {
    return( <div className='progres-bar-container'>
                <div className='progres-bar'>
                    <div className='fill-bar'>
                        <div className='progres-btn'></div>
                    </div>
                </div> 
        </div>
)
}

export default ProgresBar;
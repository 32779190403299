
import Button from '../../../UI/Button';
import './Item.css'

import { useState } from 'react';
import { useSelector,  useDispatch } from 'react-redux';

import { subtotalActions } from '../../../Store/subtotal-slice';

const Item = (props: any) => {
    const dispatch = useDispatch()
    const totalState = useSelector((state:any) => state.subtotal.value);
    
    let id = props.id;
    let price = props.price;
    let store = localStorage.getItem(JSON.stringify('cart'));
    let cart:any = {}
    if(store != null){
        cart = JSON.parse(store);
    }
    let cartItem:any = {};
    if(id in cart){
        cartItem = cart[id];
    }
    else{
        let total = Number(1*price).toFixed(2);
        cartItem = {title: props.title, price: props.price, quantity: 1,  total:total};
    }

    const [quantity, setQuantity] = useState(cartItem.quantity)

   
    const addCartItemHandler = () => {         
            if(id in cart){
                console.log('test2')
                let q = quantity+1;
                let total = Number(q*price).toFixed(2);
                setQuantity(q);
                cartItem = {title: props.title, price: props.price, quantity: q,  total: total};
            }
            dispatch(subtotalActions.incrementByAmount(Number(price)))
            cart[id]=cartItem;
            localStorage.setItem(JSON.stringify('cart'), JSON.stringify(cart));

        }
    

   
    return <div className='item-wrapper'>
            <img src={props.img} alt='item-foto' className='item-foto'></img>
            <div className='item-title-container'>
                 <div className='item-title'>{props.title}</div>
                 <div className='item-price'>${props.price}</div>
            </div>
           
            <div className='item-description'>{props.description}</div>
            <Button to ='/cart' name='Buy tokens' onClick={addCartItemHandler}></Button>
        </div>

}

export default Item;
import './ForgotPassword.css'

import LoginHeader from './LoginHeader';
import LoginBtn from './LoginBtn';
import LoginInputFild from './LoginInputFild';
import loginLayer from '../Assets/LoginBackground.svg'

const ForgotPassword = () =>{
    return(<div>
        <div className='img-wrapper'>
            <div className='img-container'>
                 <img className='img-layer' src={loginLayer} alt=''></img>
            </div>
        </div> 
        <div className='login-container forgot-password-container'>
            <LoginHeader title='Forgot password'></LoginHeader>
            <LoginInputFild name ='Email Address' type='email' placeholder='username@mail.com'></LoginInputFild>
            <LoginBtn></LoginBtn>
        </div>
    </div>
   )

}
export default ForgotPassword;
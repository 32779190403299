import {Route, Switch} from 'react-router-dom';
import Header from './Menu/Header'
import Footer from './Footer/Footer';
import Items from './Main/Items/Items';
import Cart from './Main/Cart/Cart';
import Payments from './Main/Payments/Payments';
import Login from './Login/Login';
import SignUp from './Login/SignUp';
import ForgotPassword from './Login/ForgotPassword';
import ScrollToTop from './UI/ScrollToTop';
import SelectWallet from './Main/Items/SelectWallet';
import { walletActions } from './Store/wallet-slice';

import { useSelector, useDispatch } from 'react-redux';

import './App.css'



function App() {
  const dispatch = useDispatch();
  const walletState = useSelector((state:any) => state.wallet.walletState)
  let style = '';
  if(walletState){
    style = 'wallet-backdrop-open'
  }
  else{
    style = 'wallet-backdrop-close'
  }

  const walletBackdropHamdler = () => {
    if(walletState){
      dispatch(walletActions.close())
    }
    else{
      dispatch(walletActions.open())
    }

  }
  return (
  <div>
    <Header></Header>
    <main>
      <ScrollToTop />
      <div className={style} onClick={walletBackdropHamdler}></div>
      <SelectWallet></SelectWallet>
      <Route path='/' exact>
         <Items></Items>
      </Route>
      <Route path='/cart'>
          <Cart></Cart>
      </Route>
      <Route path='/payments'>
          <Payments></Payments>
      </Route>
      <Route path='/login'>
          <Login></Login>
      </Route>
      <Route path='/signup'>
        <SignUp></SignUp>
      </Route>
      <Route path='/forgotpassword'>
        <ForgotPassword></ForgotPassword>
      </Route>
    </main>
    <Footer></Footer>

  </div>

  )
}

export default App;

import Button from '../../UI/Button';
import './Payments.css'

import { useSelector } from 'react-redux';

const Payments = () => {
    const total = useSelector((state:any) => state.subtotal.value);
    return( <div className='payment-block'>
        
        <div className='payment-form-container'>
            <form className='payment-form'>
                <div className='form-title'>BILLING DETAILS</div>
                <div className='name-input-container'>
                    <label className='payment-form-label'>First name <span className='yelow'>*</span></label>
                    <input name='first-name' type='text' placeholder='First name *' className='payment-input-style' id='first-name'></input>
                    <label className='payment-form-label'>Last name <span className='yelow'>*</span></label>
                    <input name='last-name' type='text' placeholder='Last name *' className='payment-input-style' id='last-name'></input>
                </div>
                <label className='payment-form-label'>Select a country / region <span className='yelow'>*</span></label>
                <select name='country' className='payment-input-style'>
            
                    <option id='empty-option'>Select a country / region</option>
                    <option>Ukraine</option>
                    <option>USA</option>
                    <option>Poland</option>
                </select>
                <label className='payment-form-label'>House number and street name <span className='yelow'>*</span></label>
                <input type='text' placeholder='House number and street name *' className='payment-input-style' id='adress'></input>
                <label className='payment-form-label'>Town / City <span className='yelow'>*</span></label>
                <input type='text' placeholder='Town / City *' className='payment-input-style' id='city'></input>
                <label className='payment-form-label'>Postcode / ZIP <span className='yelow'>*</span></label>
                <input type='number' placeholder='Postcode / ZIP *' className='payment-input-style' id='zip'></input>
                <label className='payment-form-label'>Phone <span className='yelow'>*</span></label>
                <input type='number' placeholder='Phone *' className='payment-input-style' id='phone'></input>
                <label className='payment-form-label'>Email address <span className='yelow'>*</span></label>
                <input type='number' placeholder='Email address *' className='payment-input-style' id='email'></input>
                <div className='form-title'>Additional information</div>
                <textarea  placeholder='Note about your order, e.g. special notes for delivery.' className='payment-input-style' id='additional'></textarea>
                
            </form>
        </div>
        <div className='mobile-payment-form-container'>

        </div>
        <div className='total-card-wrapper'>
            <div className='total-container'>
                <div  className='form-title'>Cart Totals</div>
                <div className='total-table-row'>
                    <div className='left-column'><span>Terra Token</span> * 10000</div>
                    <div className='right-column'>${total.toFixed(2)}</div>
                </div>
                <div className='total-table-row'>
                    <div className='left-column'>Subtotal</div>
                    <div className='right-column'>${total.toFixed(2)}</div>
                </div>
                <div className='total-table-row'>
                    <div className='left-column'>Coupon</div>
                    <div className='right-column'>-$00.00</div>
                </div>
                <div className='total-table-row non-bord'>
                    <div className='left-column'>Coupon</div>
                    <div className='right-column'>-$00.00</div>
                </div>
            </div>
            <div className='card-container'>
                <label className='card-form-lable'>Card number <span className='yelow'>*</span></label>
                <input type="text" placeholder="---- / ---- / ---- / ----" name="card" id="card" className='payment-input-style' required/>
                <div className='name-input-container'>
                    <div className='card-half-container'>
                        <label className='card-form-lable'>Expiry date <span className='yelow'>*</span></label>
                        <input type="text" placeholder="MM / YY" name="card" id="card" className='payment-input-style' required/>
                    </div>
                    <div className='card-half-container'>
                        <label className='card-form-lable'>Security code <span className='yelow'>*</span></label>
                        <input type="text" placeholder="XXX" name="card" id="card" className='payment-input-style' required/>
                    </div>
                </div>
                <label className='card-form-lable'>Name on Card <span className='yelow'>*</span></label>
                <input type="text" placeholder="First name / Last name" name="card" id="card" className='payment-input-style' required/>
               <Button to='./' name='proceed to checkout'></Button>
                
            </div>
        </div>
        
    </div>)
}

export default Payments;


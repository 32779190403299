
import { useSelector, useDispatch } from 'react-redux';
import './HamburgerBtn.css';

import { navActions } from '../Store/navigation-slice';

const HamburgerBtn = () => {
  const dispatch = useDispatch();
  const buttonState = useSelector(state => state.nav.navState)
  let line1 = '';
  let line2 = '';
  let line3 = '';
  let line4 = ''; 
  if(buttonState === true) {
    line1 = 'line1';
    line2 = 'line two line2';
    line3 = 'line three line3';
    line4 = 'line4';
  }
  else {
    line1 = 'line one';
    line2 = 'line two';
    line3 = 'line three';
    line4 = 'line four';
  }
  
  const buttonHandler = () => {
      
      if(buttonState === true) {
        dispatch(navActions.close());
        console.log(buttonState);
        
      }
      else {
        dispatch(navActions.open());
        console.log(buttonState);
        
      }

      
      
    }
    return <div onClick={buttonHandler} className ="hamburger">
    <span className ={line1}></span>
    <span className ={line2}></span>
    <span className ={line3}></span>
    <span className ={line4}></span>
</div>
  };
  
  export default HamburgerBtn;



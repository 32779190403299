import './Time.css'

const Time = (props:any) => {
    return (<div className='time-container'>
        <div className='time-frame center'>
            <div className='time-box center'>
                <p className='time'>{props.time}</p>
            </div>
        </div>
        <div className='time-title'>{props.title}</div>
    </div>
    )
}

export default Time;
import { configureStore } from '@reduxjs/toolkit'


import navSlice from './navigation-slice'
import walletSlice from './wallet-slice';
import subtotalSlice from './subtotal-slice';

const store = configureStore({
    reducer: { nav: navSlice.reducer, wallet: walletSlice.reducer, subtotal:subtotalSlice.reducer},
  });

export default store;
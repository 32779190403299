
import Time from './Time';
import ProgresBar from './ProgresBar';

import  './TimerBlock.css';

const TimerBlock = () => {
    return <div className='container'>
        <div className='timer-block'>
            <div className='timer-title'>Ico ands in</div>
            <div className='timer'>
                <Time time='21' title='Day' ></Time>
                <Time time='27' title='Hours'></Time>
                <Time time='57' title='Minuts'></Time>
                <Time time='15' title='Secs'></Time>
            </div>
            <ProgresBar></ProgresBar>
            <div className='bar-discription'>231 ETH of 600 ETH goal</div>
        </div>
    </div>

}

export default TimerBlock;
/* eslint-disable jsx-a11y/img-redundant-alt */
import './Cart.css'
import Button from '../../UI/Button';
import SubtotalBlock from './SubtotalBlock';
import CartItem from './CartItem';
import CartItemMobile from './CartItemMobile';

import { useSelector,  useDispatch } from 'react-redux';

import { subtotalActions } from '../../Store/subtotal-slice';


import { useState } from 'react';


const Cart = () => {
    let store = localStorage.getItem(JSON.stringify('cart'));
    let cart:any = {}
    if(store != null){
        cart = JSON.parse(store);
    }
    
 
    const [keys, setKeys] = useState(Object.keys(cart))
    
    //let keys = ;
    const resultCart:any=[];
    const resultCartMobile:any=[];
    const deleteHandler= (id:any) => {
        delete cart[id];
        localStorage.setItem(JSON.stringify('cart'), JSON.stringify(cart));
        console.log(cart)
        setKeys(Object.keys(cart))
    }

    const totalState = useSelector((state:any) => state.subtotal.value);
    console.log(totalState)
    
    
 
    for (let i=0; i < keys.length; i++){
        let id = Number(keys[i]);
        resultCart.push(<CartItem id={id} onDelete={deleteHandler} ></CartItem>)
        resultCartMobile.push(<CartItemMobile id={id} onDelete={deleteHandler}></CartItemMobile>)   
    }

    
   

    

 return(<div>
    <div className='cart-block'>
        <table>
            <thead>
                <tr>
                    <th className='deleteBtn'></th>
                    <th className='picture'></th>
                    <th className='product'>Product</th>
                    <th className='price'>Price</th>
                    <th className='quantity'>Quantity</th>
                    <th className='subtotal last'>Subtotal</th>
                </tr>

            </thead>
            <tbody>
                
               {resultCart.map((itm:any)=>itm)}

                <tr><td colSpan={6} className='last-row last'>
                    
                    <div className='coupon-form-container'>
                        <form className='coupon-form'>
                            <input className='coupon-input'>
                                
                            </input>
                            <Button to={'/'} name={'Applay coupon'}></Button>
                        </form>
                        <Button to='/' name='Apdate cart' className='update-btn'></Button>
                    </div>
                    
                </td></tr>
            </tbody>
        </table>
    </div>
    <div className='mobile-cart-block'>
       {resultCartMobile.map((itm:any)=>itm)}
        
        
    </div>
    <div className='mobile-coupon-form-container'>
        <form className='coupon-form'>
            <input className='mobile-coupon-input'></input>
            <Button to={'/'} name={'Applay coupon'} ></Button>
        </form>
    </div>
    <SubtotalBlock total={Number(totalState).toFixed(2)}></SubtotalBlock>
 </div>
 
 )
}

export default Cart;
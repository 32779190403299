import './CartItem.css'
import delBtn from '../../Assets/x-circle.svg'
import picrute from '../../Assets/token_img_1.svg'

import { useState, useEffect } from 'react'
import { useSelector,  useDispatch } from 'react-redux';

import { subtotalActions } from '../../Store/subtotal-slice';

const CartItemMobile = (props: any) => { 
    const dispatch = useDispatch()
    const totalState = useSelector((state:any) => state.subtotal.value);
    
   
    let id = props.id;
    let store = localStorage.getItem(JSON.stringify('cart'));
    let cart:any = {}
    if(store != null){
        cart = JSON.parse(store);
    }
    let cartItem = cart[id];
    let price = cartItem.price;
    let title = cartItem.title;


    
    const [quantity, setQuantity] = useState(cartItem.quantity)
    const [total, setTotal] = useState(cartItem.total);
    const plusBtnHandler = () => {
        let res = (quantity+1)
        setQuantity(res);
        console.log(quantity)
        cartItem[quantity]=res;
        cartItem[total]=res*price;
        cart[id] = cartItem
        localStorage.setItem(JSON.stringify('cart'), JSON.stringify(cart));
        dispatch(subtotalActions.incrementByAmount(price))
       

        }
    const minusBtnHandler = () => {
        if(quantity===0){

       }else{
        let res = (quantity-1)
        setQuantity(res);
        console.log(quantity)
        cartItem[quantity]=res;
        cartItem[total]=res*price;
        cart[id] = cartItem
        localStorage.setItem(JSON.stringify('cart'), JSON.stringify(cart));
        dispatch(subtotalActions.incrementByAmount(-price))
        
        }
    }
    useEffect(()=>{
        const result = Number(quantity*price).toFixed(2);
        setTotal(Number(result))
        cartItem = {title: title, price: price, quantity: quantity,  total: total}
        cart[id] = cartItem
        localStorage.setItem(JSON.stringify('cart'), JSON.stringify(cart));
       
    },[total,quantity,price])
    
    const deleteItemHandler = () => {
        dispatch(subtotalActions.incrementByAmount(-total))
        props.onDelete(id)
        
        
    }
    const quantityChangeHandler = (event:any) =>{
        event.preventDefault();
        let prevQuantity = quantity;
        let newQuantity = event.target.value
        setQuantity(Number(event.target.value))
        let delta = (newQuantity - prevQuantity)*price;
        
        
    }

        return(<div className='cart-item'>
        <button className='mobile-delBtn-wrapper center' >
             <img src={delBtn} alt='delBtn' className='mobile-delBtn' onClick={deleteItemHandler}></img>
        </button>
        
        <div className='mobile-content-wrapper'>
            <img src={picrute} alt='' className='table-img-mobile'></img>
            <div className='mobile-title-wrapper'>
                <div className='mobile-title'>Product</div>
                <div className='mobile-title'>Price</div>
            </div>
            <div className='mobile-title-wrapper'>
                <div className='mobile-product-title'>{title}</div>
                <div className='mobile-title'>${price}</div>
            </div>
            
            
                <div className='mobile-title-wrapper padding'>
                    <div className='mobile-title'>Quantity</div>
                    <div className='mobile-title'>Subtotal</div>
                </div>
                <div className='mobile-title-wrapper'>
                    <div className='mobile-quantity-form'>
                        <div className='mobile-quantity-form-btn center' onClick={minusBtnHandler}> <button className='minus-btn'>-</button></div>
                        <input type='number' className='mobile-quantity-amount' value={quantity} onChange={quantityChangeHandler}></input>
                        <div className='mobile-quantity-form-btn center'>
                            <button className='plus-btn' onClick={plusBtnHandler}>+</button>
                        </div>
                    </div>
                    <div className='mobile-title'>${total}</div>
                </div>
        </div>
        

    </div>
    
        )
       
    }

    
   
   
   
    
  

    
    


export default CartItemMobile;
import './SignUp.css'

import LoginHeader from './LoginHeader';
import LoginBtn from './LoginBtn';
import LoginInputFild from './LoginInputFild';
import loginLayer from '../Assets/LoginBackground.svg'

const SignUp = () =>{
    return(<div>
        <div className='img-wrapper'>
            <div className='img-container'>
                 <img className='img-layer' src={loginLayer} alt=''></img>
            </div>
        </div> 
        <div className='login-container signup-container'>
            <LoginHeader title='Sign Up to Mear'></LoginHeader>
            <LoginInputFild name='Username' type='text' placeholder='Guonto'></LoginInputFild>
            <LoginInputFild name='Email Address' type='email' placeholder='username@mail.com'></LoginInputFild>
            <LoginInputFild name='Password' type='password' placeholder='**************'></LoginInputFild>
            <LoginInputFild name='Confirm Password' type='password' placeholder='**************'></LoginInputFild>
            <LoginBtn></LoginBtn>
        </div>
        </div>)

}
export default SignUp;
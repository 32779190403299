import './CartItem.css'
import delBtn from '../../Assets/x-circle.svg'
import picrute from '../../Assets/token_img_1.svg'

import { useState, useEffect } from 'react'
import { useSelector,  useDispatch } from 'react-redux';

import { subtotalActions } from '../../Store/subtotal-slice';

const CartItem = (props: any) => { 
    const dispatch = useDispatch()
    const totalState = useSelector((state:any) => state.subtotal.value);
    
   
    let id = props.id;
    let store = localStorage.getItem(JSON.stringify('cart'));
    let cart:any = {}
    if(store != null){
        cart = JSON.parse(store);
    }
    let cartItem = cart[id];
    let price = cartItem.price;
    let title = cartItem.title;


    
    const [quantity, setQuantity] = useState(cartItem.quantity)
    const [total, setTotal] = useState(cartItem.total);
    const plusBtnHandler = () => {
        let res = (quantity+1)
        setQuantity(res);
        console.log(quantity)
        cartItem[quantity]=res;
        cartItem[total]=res*price;
        cart[id] = cartItem
        localStorage.setItem(JSON.stringify('cart'), JSON.stringify(cart));
        dispatch(subtotalActions.incrementByAmount(price))
       

        }
    const minusBtnHandler = () => {
        if(quantity===0){

       }else{
        let res = (quantity-1)
        setQuantity(res);
        console.log(quantity)
        cartItem[quantity]=res;
        cartItem[total]=res*price;
        cart[id] = cartItem
        localStorage.setItem(JSON.stringify('cart'), JSON.stringify(cart));
        dispatch(subtotalActions.incrementByAmount(-price))
        
        }
    }
    useEffect(()=>{
        const result = Number(quantity*price).toFixed(2);
        setTotal(Number(result))
        cartItem = {title: title, price: price, quantity: quantity,  total: total}
        cart[id] = cartItem
        localStorage.setItem(JSON.stringify('cart'), JSON.stringify(cart));
       
    },[total,quantity,price])
    
    const deleteItemHandler = () => {
        dispatch(subtotalActions.incrementByAmount(-total))
        props.onDelete(id)
        
        
    }
    const quantityChangeHandler = (event:any) =>{
        event.preventDefault();
        let prevQuantity = quantity;
        let newQuantity = event.target.value
        setQuantity(Number(event.target.value))
        let delta = (newQuantity - prevQuantity)*price;
        
        
    }
    
        return(<tr className='tabl-item-row'>
            <td>
                <img src={delBtn} alt='' className='del-img' onClick={deleteItemHandler} ></img>
            </td>
            <td>
                <img src={picrute} alt='pictur' className='table-img'></img>
            </td>
            <td className='product-title'>{title}</td>
            <td className='product-price'>${price}</td>
            <td>
                <div className='quantity-form'>
                    <div className='quantity-form-btn center'>
                        <button className='plus-btn' onClick={minusBtnHandler}>-</button>
                    </div>
                    <input type='number' className='quantity-amount' value={quantity} onChange={quantityChangeHandler}></input>
                    <div className='quantity-form-btn center' onClick={plusBtnHandler}> <button className='minus-btn'>+</button></div>
                </div>
            </td>
            <td className='product-price last'>${total}</td>
        </tr> 
    
        )

  
   
   
   
    
  

    
    
}

export default CartItem;
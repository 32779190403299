import Button from "../../UI/Button";

import './SubtotalBlock.css'

const SubtotalBlock = (props: any) => {
    return(<div className="subtotal-block">
        <div className='subtotal-wrapper'>
            <div className='subtotal-title'>Cart Totals</div>
            <table className='subtotal-table'>
                <tbody>
                    <tr>
                        <td className=' first-row first-col'>Subtotal</td>
                        <td className=' first-row second-col'>${props.total}</td>
                    </tr>
                    <tr>
                        <td className='second-row first-col'>Coupon</td>
                        <td className='second-row second-col'>$0</td>
                    </tr>
                <tr>
                    <td className='third-row left-text'>Total</td>
                    <td className='third-row right-text'>${props.total}</td>
                </tr>
                </tbody>
            </table>
            <Button to='/payments' name='proceed to checkout'></Button>
        </div>
    </div>
)

}
export default SubtotalBlock;
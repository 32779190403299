import { createSlice } from '@reduxjs/toolkit'


let store = localStorage.getItem(JSON.stringify('cart'));
    let cart = {} 
    let keys = 0
    if(store != null){
        cart = JSON.parse(store);
        keys= Object.keys(cart)
    }
   

    let totalResult = 0;
    
    for (let i=0; i < keys.length; i++){
        let id = Number(keys[i]);
        totalResult = totalResult + Number(cart[id].total) 
    }
    


const subtotalSlice = createSlice({
    name: 'subtotal',
    initialState: { value: totalResult},
    reducers: {
      plus(state) {
        state.value =  state.value + 1 ;
      },
      mines(state) {
        state.subtotalState =  state.value - 1 ;
      },
      incrementByAmount: (state, action) => {
        console.log(action.payload)
        state.value += action.payload
       
      },
    }
  });
  
  export const subtotalActions = subtotalSlice.actions;
  
  export default subtotalSlice;
import './Login.css'
import './LoginInputFild.css'
import '../UI/Fontstyle.css'

import { NavLink } from 'react-router-dom';
import LoginBtn from './LoginBtn';
import LoginHeader from './LoginHeader';
import LoginInputFild from './LoginInputFild';

import loginLayer from '../Assets/LoginBackground.svg'


const Login = () => {
    return(<div>
        <div className='img-wrapper'>
            <div className='img-container'>
                 <img className='img-layer' src={loginLayer} alt=''></img>
            </div>
        </div>
        <div className='login-container login'>
        <LoginHeader title='Login in to Mear'></LoginHeader>
        <LoginInputFild name='Username or Email Address' type='email' placeholder='username@mail.com'></LoginInputFild>
        <div className='fild-container'>
            <div className='login-text-wrapper'>
            <div className='fild-name font-inter-normal-600'>Password</div> 
            <NavLink to='/forgotpassword' className='forgot-password-text font-inter-normal-400'>Forgot password?</NavLink>
            </div>
            <input type='password' placeholder='**************' id='logo-name' className='login-input font-inter-normal-500'></input>
        </div>
        <LoginBtn></LoginBtn>
        <div className='or-block'>
            <div className='or-line'></div>
            <div className='or-text font-inter-normal-300'>OR</div>
            <div className='or-line'></div>
        </div>
        <div className='login-btn-container'>
            <div id='fbBtn' className='font-inter-normal-600'>
                <div className='fbBtn logoBtn-img'></div>
                <div>Facebook</div>
            </div>
            <div id='googlBtn' className='font-inter-normal-600'>
                <div className='googlBtn logoBtn-img'></div>
                <div>Google</div>
            </div>
        </div>
        <div className='tryBtn font-inter-normal-600'>Try It</div>
        <div className='registretion-link font-inter-normal-600'>Don’t have an account? 
        <NavLink to='./signup'> Get started!</NavLink></div>
        </div>
</div>
        )
}

export default Login;